//
// Display a card for a feature of the website.
//
import {Card, CardActionArea, CardContent} from "@mui/material";
import {PropsWithChildren} from "react";

interface FeatureProps {
    title: string;
    url: string;
}

function FeatureCard({title,url,children}: PropsWithChildren<FeatureProps>) {

    // We reload the page when changing features.
    function navigate() {
        window.location.href = url;
    }

    return (
        <CardActionArea onClick={navigate}>
            <Card elevation={5}>
                <CardContent>
                    <h2>{title}</h2>
                    {children}
                </CardContent>
            </Card>
        </CardActionArea>
    );
}

export default FeatureCard