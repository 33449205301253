import {Container} from "@mui/material";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <Container component="footer" maxWidth={"md"}>
            <div className="footer-row">
                WindyTHINK is a <a href="https://windypundit.com" target="_blank">windypundit.com</a> project
            </div>
            <div className="footer-row">
                <Link to="/Privacy">Privacy Notice</Link>
            </div>
            <div className="footer-row">
                &copy; 2024 - Mark Draughn
            </div>
        </Container>
    )
}

export default Footer;