import {CircularProgress, MenuItem, Select, SelectProps} from "@mui/material";
import {useApiClient} from "../../context/ApiClientProvider.tsx";
import {useQuery} from "@tanstack/react-query";
import {FieldHookConfig} from "formik/dist/Field";
import {useField} from "formik";

export interface SpellingBeeDictItem {
    readonly name: string,
    readonly tag: string,
}

interface SpellingBeeDictPdu {
    readonly default: string,
    readonly dictionaries: ReadonlyArray<SpellingBeeDictItem>
}

const SpellingBeeDictSelector = (props: FieldHookConfig<string>) => {

    // Setup formik field.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, , fieldHelper] = useField(props);

    // const {value} = props as {value: string};

    const {connection} = useApiClient();
    async function getDictionaries():Promise<SpellingBeeDictPdu> {
        const res = await connection.get('/api/spellingBee/DictList');
        await fieldHelper.setValue(res.data.default);
        return await res.data;
    }

    const query = useQuery({
        queryKey: ['SpellingBeeDictSelector'],
        queryFn: getDictionaries
    });

    // console.log("Query:"+JSON.stringify(query))

    // If not read, display something else.
    if (query.isPending) {
        return (<CircularProgress/>);
    }
    if (query.isError) {
        return <span>Error: {query.error.message}</span>
    }

    // Unpack the dictionary data.
    const dictPdu = query.data;

    return(
        <>
            <Select {...field} {...(props as SelectProps<unknown>)} >{
                dictPdu.dictionaries.map((elt,index) =>
                    <MenuItem key={index} value={elt.tag} >{elt.name}</MenuItem>)}
            </Select>
        </>
    )
}

export default SpellingBeeDictSelector;