import {Box, Container, Link} from "@mui/material";
import windyThinkLogo from "../assets/windythink-banner.png";
import WindyAppBar from "./WindyAppBar.tsx";

interface HeaderProps {
    mainNav: ReadonlyArray<{
        title: string;
        url: string;
    }>;
}

function Header({mainNav}: HeaderProps) {
    return (
        <header>
            <Container maxWidth="md">
                <Box>
                    <Link href="/"><Box
                        component="img"
                        sx={{marginLeft:-2}}
                        src={windyThinkLogo} alt="WindyTHINK"/></Link>
                </Box>
            </Container>
            <WindyAppBar mainNav={mainNav}/>
        </header>
    );
}

export default Header;