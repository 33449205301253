
import {CircularProgress} from "@mui/material";
import {UseQueryResult} from "@tanstack/react-query";

interface SpellingBeeResultItem {
    readonly word: string,
    readonly score: number,
    readonly isPangram: boolean
}

interface SpellingBeeResultPdu {
    readonly words: ReadonlyArray<SpellingBeeResultItem>
}

function SpellingBeePageResult({queryHandle}:{queryHandle:UseQueryResult<SpellingBeeResultPdu>}) {

    if (queryHandle.isFetching) {
        return (<CircularProgress/>);
    }
    if (queryHandle.isError) {
        return <span className='wt-error-message'>Error: {queryHandle.error.message}</span>
    }

    const resultData = queryHandle.data;
    if (!resultData) {
        return <span/>
    }

    const numResults = resultData.words.length;
    return <>
       <div id="resultsArea">
            <h2>Results:</h2>
            <p>Found {numResults} word{numResults === 1 ? "" : "s"}</p>
            {resultData ? (<table>
                    <tbody>{resultData.words.map((w, index) => (<tr key={index}>
                            <td style={{fontFamily: "monospace", fontSize: 20, paddingRight: 50}}>{w.word}</td>
                            <td>({w.score} pt{w.score === 1 ? "" : "s"}{w.isPangram ? ", pangram" : ""})</td>
                        </tr>))}
                    </tbody>
                </table>) : (<CircularProgress/>)}
       </div>
    </>;
}

export default SpellingBeePageResult;