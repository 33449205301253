import {ReactNode, createContext, useContext} from "react";
import axios, { AxiosInstance } from 'axios'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

// Axios connection, uses Vite if defined.
console.log(`Api location ${import.meta.env.VITE_API_HOST}`)
const axiosInstance = axios.create({baseURL : import.meta.env.VITE_API_HOST});

// Client for doing react-query operations.
const queryClient = new QueryClient();

// Context.
interface ApiClient {
    readonly connection: AxiosInstance;
    readonly queryClient: QueryClient;
}

// Build the context instance for this page.
const apiClient =
    {
        connection: axiosInstance,
        queryClient: queryClient
    };

// React context wrapper.
const ApiClientContext = createContext<ApiClient|null>(null);

// Define the hook.
const useApiClient = () => {
    const apiClient = useContext(ApiClientContext);

    if (!apiClient) {
        throw new Error(
            "useApiClient has to be used within <ApiClientProvider>"
        );
    }

    return apiClient;
};

// Provide the client contect.
const ApiClientProvider = ({children}:{children:ReactNode}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ApiClientContext.Provider value={apiClient}>
                <>
                    {children}
                </>
            </ApiClientContext.Provider>
        </QueryClientProvider>
    )
};

export {ApiClientProvider, useApiClient};