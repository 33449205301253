import SpellingBeePageDescription from "./SpellingBeePageDescription.tsx";
import SpellingBeeDictSelector from "./SpellingBeeDictSelector.tsx";
import {useState} from "react";
import {useApiClient} from "../../context/ApiClientProvider.tsx";
import {useQuery} from "@tanstack/react-query";
import SpellingBeePageResult from "./SpellingBeePageResult.tsx";
import {Button, FormGroup} from "@mui/material";
import Grid from "@mui/material/Grid";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import LetterTextBox from "../../components/LetterTextBox.tsx";

// The query sent to the server.
export interface SpellingBeeQueryPdu {
    readonly requiredChar: string,
    readonly otherChars: string,
    readonly dictionary: string
}

// A single returned word.
interface SpellingBeeResultItem {
    readonly word: string,
    readonly score: number,
    readonly isPangram: boolean
}

// A response from the server.
interface SpellingBeeResultPdu {
    readonly words: ReadonlyArray<SpellingBeeResultItem>
}

function SpellingBeePage() {
    const {connection} = useApiClient();
    const [activeQuery,setActiveQuery] = useState<SpellingBeeQueryPdu|null>(null);

    // Set up the validation.
    const validationSchema = Yup.object().shape({
        requiredChar: Yup.string()
            .lowercase()
            .matches(/^[A-Za-z]$/,"Must enter a letter from a to z")
            .length(1, 'Must specify exactly 1 letter')
            .required('Please specify a required letter'),
        otherChars: Yup.string()
            .lowercase()
            .matches(/(^[A-Za-z]*$)+/,"Must be only letters from a to z")
            .length(6, 'Must specify exactly 6 letters')
            .test("unique", "Letters must not be repeated",(value)=>
                new Set(value?.split('')).size===value?.length)
            .test("unique", "Must not include center character",(value, ctx)=> {
                const combined = `${value}${ctx.parent.requiredChar}`;
                return new Set(combined?.split('')).size === combined?.length;
            })
            .required('Please specify additional letters.'),
        dictionary: Yup.string()
            .required('Please specify a dictionary.')
    });

    // Receives the formik submit.
    const handleSubmit = async (userQuery: SpellingBeeQueryPdu) => {
        const validQuery = await validationSchema.validate(userQuery);
        setActiveQuery(validQuery);
    };

    async function fetchWords(): Promise<SpellingBeeResultPdu> {
        if (activeQuery !== null) {
            const url = `/api/spellingBee/WordList/${activeQuery.dictionary}`;
            const params = {
                requiredChar: activeQuery.requiredChar, otherChars: activeQuery.otherChars,
            };
            console.log(`fetching ${url} ${JSON.stringify(params)}`)
            const res = await connection.get(url, {params: params});
            return (await res.data) as SpellingBeeResultPdu;
        }
        return { words: [] }
    }

    const queryHandle = useQuery({
        queryKey: ['SpellingBeeGetWords',activeQuery], queryFn: fetchWords, enabled: activeQuery != null
    });

    const initQuery:SpellingBeeQueryPdu = {requiredChar:'',otherChars:'',dictionary:''}
    return <>
            <div>
                <h1><i>New York Times</i> Spelling Bee Word Generator</h1>
                <SpellingBeePageDescription/>
            </div>
            <div>
                <h2>Enter information about today's Spelling Bee puzzle:</h2>
                <Formik validationSchema={validationSchema}
                        initialValues={initQuery}
                        onSubmit={handleSubmit}>
                    <Form>
                        <FormGroup>
                            <ErrorMessage name="ValidationError" />
                            <ErrorMessage name="form" />
                            <ErrorMessage name="chars-unique" />
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <label>Center letter</label>
                                </Grid>
                                <Grid item xs={9}>
                                    <LetterTextBox name="requiredChar" numChars={1} />
                                    <ErrorMessage name="requiredChar" component='span' className='wt-error-message'/>
                                </Grid>
                                <Grid item xs={3}>
                                    <label>Outer 6 letters</label>
                                </Grid>
                                <Grid item xs={9}>
                                    <LetterTextBox name="otherChars" numChars={6} />
                                    <ErrorMessage name="otherChars" component='span' className='wt-error-message'/>
                                </Grid>
                                <Grid item xs={3}>
                                    <label>Dictionary to search</label>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field as={SpellingBeeDictSelector} name="dictionary" />
                                    <ErrorMessage name="dictionary" component='span' className='wt-error-message'/>
                                </Grid>
                                <Grid item xs={9}>
                                    <Button variant={"contained"} type={"submit"} >Get Words</Button>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Form>
                </Formik>
            </div>
            <SpellingBeePageResult queryHandle={queryHandle}/>
        </>
}

export default SpellingBeePage;