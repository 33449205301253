import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Container, Link, Stack} from "@mui/material";

interface WindyAppBarProps {
    mainNav: ReadonlyArray<{
        title: string;
        url: string;
    }>;
}

function WindyAppBar(props: WindyAppBarProps) {
    const {mainNav} = props;
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Container maxWidth="md">
                    <Toolbar disableGutters={true}>
                        <Typography component="span" color="white" sx={{flexGrow: 1}}>
                            <Stack direction="row" spacing={2}>
                            {mainNav.map((elt, index) => (
                                <Link
                                    key={index}
                                    underline="hover"
                                    color="inherit"
                                    href={elt.url}>{elt.title}</Link>
                            ))}
                            </Stack>
                        </Typography>
                        {/*<Button color="inherit">Login</Button>*/}
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    )
}

export default WindyAppBar