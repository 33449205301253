import {
    Container,
    createTheme,
    CssBaseline,
    ThemeProvider,
} from "@mui/material";
import Header from "../components/Header.tsx";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { ApiClientProvider } from "../context/ApiClientProvider.tsx";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';
import Footer from "../components/Footer.tsx";

const defaultTheme = createTheme();
const mainNav = [
    { title: 'Home', url: '/' },
    { title: 'Spelling Bee', url: '/spelling-bee' },
    { title: 'Privacy', url: '/privacy' },
];

function Root() {
    const location = useLocation();
    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.pageview(path);
    }, [location]);
    const containerSize = "md"

    return (
        <ApiClientProvider>
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline/>
                <Header mainNav={mainNav}/>
                <Container maxWidth={containerSize}>
                    <Box component="main">
                        <Outlet />
                    </Box>
                </Container>
                <Footer/>
            </ThemeProvider>
        </ApiClientProvider>
    )
}

export default Root
