import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import SpellingBeePage from "./routes/spelling-bee/SpellingBeePage.tsx";
import Root from "./routes/Root.tsx";
import ErrorPage from "./error-page.tsx";
import PrivacyPage from "./routes/PrivacyPage.tsx";
import HomePage from "./routes/HomePage.tsx";
import ReactGA from 'react-ga';

const router = createBrowserRouter([
    { path: "/", element: <Root/>, errorElement: <ErrorPage />, children:[
            { index: true, element: <HomePage/> },
            { path: "/index", element: <HomePage/> },
            { path: "/index.html", element: <HomePage/> },
            { path: "/spelling-bee", element: <SpellingBeePage/> },
            { path: "/SpellingBeeWordGenerator", element: <SpellingBeePage/> },
            { path: "/privacy", element: <PrivacyPage/>}
        ]},
]);

declare const WindyThink_GoogleAnalyticsTag: string;
if (typeof WindyThink_GoogleAnalyticsTag !== 'undefined' && WindyThink_GoogleAnalyticsTag !== null) {
    ReactGA.initialize(WindyThink_GoogleAnalyticsTag);
    console.log(`Initialized GA with tag ${WindyThink_GoogleAnalyticsTag}`);
} else {
    console.log("No GA tag provided");
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
