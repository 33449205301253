import Grid from "@mui/material/Grid";
import FeatureCard from "../components/FeatureCard.tsx";

function HomePage() {

    return (
        <>
            <h1>Welcome</h1>
            <p>The WindyTHINK website is part of a project to
            provide some possibly interesting and (perhaps) useful tools for visitors
            as a side effect of teaching myself some interesting software technology.</p>
            <p>Currently, there's only one working page:</p>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FeatureCard title="Spelling Bee" url="/spelling-bee">
                        <p>A tool for generating words for the <i>New York Times</i> Spelling Bee puzzle.</p>
                    </FeatureCard>
                </Grid>
            </Grid>
        </>
    )
}

export default HomePage
