import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import Typography from "@mui/material/Typography";

const SpellingBeePageDescription = () => (
    <>
        <div>
            <p>
                This page generates lists of words matching the
                <em>New York Times</em> <a href="https://www.nytimes.com/puzzles/spelling-bee" target="_blank">Spelling
                Bee</a> puzzle.
                Just enter the center letter from the puzzle, the remaining 6 letters, and optionally, the dictionary
                you wish to use.
            </p>
        </div>
        <Accordion>
            <AccordionSummary
                expandIcon={'v'}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{width: '33%', flexShrink: 0}}>
                    Note
                </Typography>
                <Typography sx={{color: 'text.secondary'}}>How likely are these words to be accepted?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    The <i>New York Times</i> Spelling Bee validates words against a privately curated
                    custom dictionary that they have not made public.
                    This page draws words from a set of open-source dictionaries to generate a list of words that match
                    the letters specified in the <i>New York Times</i> Spelling Bee puzzle.
                    Thus all of the words produced by this page will be made up of letters from the Spelling Bee
                    puzzle, there's no guarantee that any of the words will be accepted by the Spelling Bee dictionary.
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={'v'}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{width: '33%', flexShrink: 0}}>
                    Word Sources
                </Typography>
                <Typography sx={{color: 'text.secondary'}}>Where do these words come from?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <p>The words generated by this page come from three available dictionaries, assembled as follows:</p>
                    <ul>
                        <li>
                            Limited dictionary - 146,513 words
                            <ul>
                                <li>List of <a
                                    href="https://www.kaggle.com/datasets/kameshsoft/stemmed-and-lementized-english-words">Stemmed
                                    and Lementized English words</a> from Kaggle
                                </li>
                                <li>List of <a
                                    href="https://www.kaggle.com/datasets/kameshsoft/stemmed-and-lementized-english-words">10000
                                    Words</a> from Kaggle
                                </li>
                            </ul>
                        </li>
                        <li>
                            Standard dictionary - 719,972 words
                            <ul>
                                <li>All the words in the Limited dictionary</li>
                                <li>Words extracted from the <a href="https://en.wiktionary.org/">English languages
                                    Wiktionary</a></li>
                            </ul>
                        </li>
                        <li>
                            Enhanced dictionary - 837,983 words
                            <ul>
                                <li>All the words in the Standard dictionary</li>
                                <li>List of <a
                                    href="https://www.kaggle.com/datasets/ruchi798/part-of-speech-tagging">370k
                                    English words corpus</a> from Kaggle
                                </li>
                                <li>List of <a href="https://www.kaggle.com/datasets/yk1598/479k-english-words">479k
                                    English Words</a> from Kaggle
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </AccordionDetails>
        </Accordion>
    </>
);
export default SpellingBeePageDescription;