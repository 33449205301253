import {useField} from "formik";
import {TextField} from "@mui/material";

const LetterTextBox = ({numChars, ...props}:{numChars:number,name:string}) => {
    // Setup formik field.
    // @ts-ignore
    const [field, meta] = useField(props);

    // Make it look like block text
    const widthExpr:string = `${numChars+1}em`;
    const inputStyle = {fontFamily:"monospace",fontSize:25,width:widthExpr};

    // Render it.
    return (
        <>
            <TextField type="text" InputProps={{style:inputStyle}} {...field} {...props}/>
        </>
    );
}

export default LetterTextBox